import React, { useEffect, useState } from "react";
import Loading from "../../../shared/Loading";
import LocationSuccessModal from "../../../shared/LocationSuccessModal";
import LocationHeading from "../../../shared/LocationHeading";
import { WithContext as ReactTags } from "react-tag-input";
import addPhoto from "../../../assets/Images/add_photo_alternate.png";
import addText from "../../../assets/Images/insert_text.png";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../../services/api";
import logoOne from "../../../assets/Images/logoOne.png";
import { useUserContext } from "../../../components/context/UserContext";
import client from "../../../services/Client";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function EditBlog() {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bannerImage, setBannerImage] = useState({});
  const [title, setTitle] = useState("");
  const [categories, setCategories] = useState("");
  const [tags, setTags] = useState([]);
  const [metaTags, setMetaTags] = useState([]);
  const [content, setContent] = useState([]);
  const { state } = useUserContext();

  const token = state.token;

  const navigate = useNavigate();

  const { id } = useParams();

  const openSuccessModal = () => {
    setIsSuccessModalOpen(true);
    setIsOverlayVisible(false);
    document.body.classList.add("no-scroll");
    document.body.classList.remove("overlay");
  };
  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setIsOverlayVisible(false);
    document.body.classList.remove("no-scroll");
    navigate("/blog");
  };

  const handleAddDivClick = () => {
    setContent((prevContent) => [
      ...prevContent,
      { addContentText: "", imageFile: null, imageUrl: "" },
    ]);
  };

  const handleDeleteClick = (index) => {
    const updatedContent = [...content];
    updatedContent.splice(index, 1); // Remove the content at the specified index
    setContent(updatedContent);
  };

  const handleImageChange = (event, index) => {
    const updatedContent = [...content];
    if (!updatedContent) {
      return;
    }
    updatedContent[index] = {
      ...updatedContent[index],
      imageUrl: URL.createObjectURL(event.target.files[0]),
      imageFile: event.target.files[0],
    };
    setContent(updatedContent);
  };

  const handleContentTextChange = (text, index) => {
    const updatedContent = [...content];
    updatedContent[index] = {
      ...updatedContent[index],
      addContentText: text,
    };
    setContent(updatedContent);
  };

  const onBannerImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }

    const imageUrl = URL.createObjectURL(selectedFile);
    setBannerImage({
      url: imageUrl,
      file: selectedFile,
    });
    // console.log("image file", imageUrl);
  };

  const handleDeleteImage = (index) => {
    const updatedContent = [...content]; // Create a copy of the content array
    updatedContent[index].imageUrl = "";
    updatedContent[index].imageFile = null;
    setContent(updatedContent);
  };

  const handleDeleteOne = (i) => {
    const newTags = tags.slice();
    newTags.splice(i, 1);
    setTags(newTags);
  };

  const handleDeleteTwo = (i) => {
    const newTags = metaTags.slice();
    newTags.splice(i, 1);
    setMetaTags(newTags);
  };

  const handleAdditionOne = (tag) => {
    console.log("tag from tags", tag);
    console.log("tag from tags text", tag.text);
    setTags([...tags, tag]);
  };

  const handleAdditionTwo = (tag) => {
    console.log("tag from meta", tag);
    setMetaTags([...metaTags, tag]);
  };

  const clearImage = () => {
    setBannerImage(null);
  };

  const loadBlog = async () => {
    try {
      setIsLoading(true);
      const response = await client.get(`admin/get-blog/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTitle(response.data.blogs.title);
      const blogData = response.data.blogs;
      setCategories(blogData.categories);
      // Convert tags and metaTags to tag objects
      const tagsArray = blogData.tags.map((tag) => ({
        id: tag, // Use a unique identifier, tag itself in this case
        text: tag,
      }));

      const metaTagsArray = blogData.metaTags.map((metaTag) => ({
        id: metaTag, // Use a unique identifier, metaTag itself in this case
        text: metaTag,
      }));
      // Set the tag arrays in state
      setTags(tagsArray);
      setMetaTags(metaTagsArray);
      const loadBannerImage = response.data.blogs.bannerImage;
      if (loadBannerImage) {
        setBannerImage({
          url: loadBannerImage, // Set the image URL
        });
      }

      const blogContentData = response.data.blogs.addContent;

      setContent(
        blogContentData.map((contentItem) => ({
          addContentText: contentItem.text, // Populate the text
          imageUrl: contentItem.images, // Populate the image URL
          imageFile: null,
        }))
      );
      setIsLoading(false);

      console.log("res is:", response);
    } catch (error) {
      console.log("Error loading blog data:", error);
    }
  };
  useEffect(() => {
    loadBlog();
  }, []);

  const onSubmit = async (e, status) => {
    e.preventDefault();
    function displayError(message) {
      toast.error(message, {
        toastClassName: "my-toast-body-light",
      });
    }
    if (!title) {
      displayError("Title is required.");
      return;
    }
    if (tags.length === 0) {
      displayError("Add at least one tag.");
      return;
    }
    if (metaTags.length === 0) {
      displayError("Add at least one meta tag.");
      return;
    }
    if (!bannerImage) {
      displayError("Add banner Image.");
      return;
    }
    try {
      setIsLoading(true); // Show loading animation
      const formData = new FormData();
      formData.append("title", title);
      formData.append("status", status);
      formData.append("categories", categories);

      for (const tag of tags) {
        formData.append("tags", tag.text);
      }

      for (const metaTag of metaTags) {
        formData.append("metaTags", metaTag.text);
      }

      // Append image file to the form data
      if (bannerImage) {
        if (bannerImage.file) {
          formData.append("bannerImage", bannerImage.file);
        } else if (bannerImage.url) {
          formData.append("bannerImage", bannerImage.url);
        }
      }

      for (let i = 0; i < content.length; i++) {
        formData.append("addContentText", content[i].addContentText);

        // Check if there's a new image and append it to addedImage
        if (content[i].imageFile) {
          formData.append(`addContentImage#${i}`, content[i].imageFile);
        } else if (content[i].imageUrl) {
          // If there's an existing image URL, append it to addedContentImageUrl
          formData.append(`addedContentImageUrl#${i}`, content[i].imageUrl);
        }
      }
      // console.log("Form Data:", formData);
      await api.updateBlog(id, formData, token); // Pass formData here
      setIsSuccessModalOpen(true);
      openSuccessModal(true);
      // console.log("edited response :", response);/
    } catch (error) {
      console.log("Error updating blog :", error);
    } finally {
      setIsLoading(false); // Hide loading animation
    }
  };

  if (isLoading) {
    return (
      <div className="overlay">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="add-location-main">
        <LocationHeading
          title={[
            <Link to={"/blog"}>
              <i
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
                className="fa fa-long-arrow-left me-2"
                aria-hidden="true"></i>
            </Link>,
            "Edit Blog Details",
          ]}
        />
        <div className="blog-info-main">
          <div className="blog-head">
            <h3>Blog information</h3>
          </div>
          <div className="blog-info">
            <div className="title">
              <label>Title</label>
              <input
                type="text"
                placeholder="Kendle"
                value={title} // Set the value from state
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="blog-option">
              <div className="input-group-button">
                <label>Cateories</label>
                <input
                  type="text"
                  placeholder="Add your site Name..."
                  name="name"
                  value={categories}
                  onChange={(e) => setCategories(e.target.value)}
                />
              </div>
              <div className="">
                <label>Tags</label>
                <div>
                  <ReactTags
                    tags={tags}
                    handleDelete={handleDeleteOne}
                    handleAddition={handleAdditionOne}
                    inputFieldPosition="top"
                  />
                </div>
              </div>
              <div className="input-group-button">
                <label>Meta Tags</label>
                <div>
                  <ReactTags
                    tags={metaTags}
                    handleDelete={handleDeleteTwo}
                    handleAddition={handleAdditionTwo}
                    inputFieldPosition="top"
                  />
                </div>
              </div>
            </div>
            <div className="blog-banner">
              <span>Banner image</span>
              <div className="blog_banner-image">
                <div className="img-upload">
                  {!bannerImage ? (
                    <div>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={onBannerImageChange}
                      />
                      <div className="upload-text">
                        <i
                          className="fa fa-cloud-upload"
                          aria-hidden="true"></i>
                        <span>Upload photo</span>
                        <p>(Recommended size1920x350)</p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <img
                        src={bannerImage.url}
                        alt="Selected"
                        className="selected-image"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          padding: "5px",
                          position: "absolute",
                          top: "0",
                          left: "0",
                        }}
                      />
                      <span className="delete-icon-image" onClick={clearImage}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <h4 className="content_tag">Add Content</h4>
            {content.length > 0 ? (
              content.map((item, index) => (
                <div key={index} className="blog-content">
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="blog-delete_one">
                      <button onClick={handleDeleteClick}>
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                      </button>
                    </div>
                    {index === content.length - 1 && (
                      <div className="blog-delete_two">
                        <button onClick={handleAddDivClick}>
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="add-content-nextBlog">
                    <Container fluid>
                      <Row
                        className={`add_content-row ${
                          index % 2 === 0 ? "row-right" : "row-left"
                        }`}>
                        <Col lg="3">
                          {content[index].imageUrl !== "" ? (
                            <div className="uploaded-image">
                              <img
                                src={content[index].imageUrl || logoOne}
                                alt="Selected"
                                className="selected_image"
                              />
                              <button
                                className="delete-icon"
                                onClick={() => handleDeleteImage(index)}>
                                <i
                                  className="fa fa-trash-o"
                                  aria-hidden="true"></i>
                              </button>
                            </div>
                          ) : (
                            <div class="img-upload">
                              <input
                                type="file"
                                id={`file-input-${index}`}
                                name={`file-input-${index}`}
                                accept="image/*"
                                onChange={(event) =>
                                  handleImageChange(event, index)
                                } // Make sure this line is correct
                              />
                              <div class="upload-text">
                                <i
                                  class="fa fa-cloud-upload"
                                  aria-hidden="true"></i>
                                <span>Upload photo</span>
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col lg="9">
                          <ReactQuill
                            theme="snow"
                            value={item.addContentText || ""} // Provide a default value if addContentText is undefined
                            onChange={(content) =>
                              handleContentTextChange(content, index)
                            }
                            modules={{
                              toolbar: false,
                            }}
                          />

                          {/* <textarea
                            name=""
                            id=""
                            rows="6"
                            value={item.addContentText} // Use the correct value from the content array
                            onChange={(event) =>
                              handleContentTextChange(event.target.value, index)
                            }></textarea>  */}
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              ))
            ) : (
              <div className="blog-content">
                <div className="add-content">
                  <div className="add-img-text">
                    <span onClick={handleAddDivClick}>
                      <img src={addPhoto} alt="add" />
                    </span>
                    <label>Image</label>
                  </div>
                  <div className="add-img-text">
                    <span onClick={handleAddDivClick}>
                      <img src={addText} alt="add text" />
                    </span>
                    <label>Text</label>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="blog-btn">
          <div className="blog-cancel">
            <Link to={"/blog"}>
              <button className="cancle-btn my-5">Cancel</button>
            </Link>
          </div>
          <div className="blog-save">
            {/* <button
              onClick={(e) => handleAddBlog(e, "drafts")}
              className="draft-btn">
              Save as draft
            </button>  */}

            <button onClick={(e) => onSubmit(e, "published")}>Save</button>
          </div>
        </div>
      </div>
      {isSuccessModalOpen && <div className="overlay"></div>}
      <LocationSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
        SuccessMessage={"Success!"}
        ModalMessage={"Your Blog has been updated successfully"}
      />
    </>
  );
}

export default EditBlog;
